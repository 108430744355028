import { getAnalytics, logEvent } from 'firebase/analytics';
import GenericPerson from '@/models/taaxii.com/taaxiiGenericPerson';
import msg from '@/services/userMsg';
import {
  PassengerModel, Direccion, SeguimientoData,
} from '@/models/requests-and-responses/seguimiento';
import type { Person } from '@/models/Person';
import type { SolicitudParada, SolicitudPasaje } from '@/models/ExtrasData';
import { Coords, defaultCoords, UserCoords } from './Coords';

export const utils = {
  /*
   * https://stackoverflow.com/a/38858508
   */
  collateBy: (f) => (g) => (xs) => xs.reduce((m, x) => {
    const v = f(x);
    return m.set(v, g(m.get(v), x));
  }, new Map()),

  capitalize(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  },
};

export function capitalizeText(text: string): string {
  if (!text) return '';
  return text.split(' ').reduce((array, word) => {
    word.replaceAll(/\s+/g, '');
    if (word) array.push(utils.capitalize(word.trim()));
    return array;
  }, [] as string[]).join(' ');
}

export function toTwoDecimals(number: number): string {
  return number?.toFixed(2).replace(/[.,]00$/, '') || '-';
}

export const validators = {
  isFilled(object: { [key: string]: string }) {
    return Object.values(object).every(this.isValidString);
  },
  isValidUser: (user: string) => (/^[a-zA-Z0-9_-]+$/.test(user) || validators.isValidEmail(user)) && user.length < 100,
  isValidPass: (pass: string) => /[a-z]/.test(pass) && /[A-Z]/.test(pass) && /[0-9]/.test(pass)
    && pass.length >= 8 && pass.length <= 30,
  isValidName: (name: string) => /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/.test(name) && name.length < 100,
  isValidDNI: (dni: string) => /^[0-9]{7,9}$/.test(dni),
  isValidEmail: (email: string) => /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) && email.length < 100,
  /** Este validador es generoso. Puede ocurrir que haya números de teléfonos incorrectos
   * pero que la función diga que son correctos. Al menos no ocurre al reves.
   * Whatsapp es bastante flexible con los números de teléfono. Ver TX-1726.
   */
  isValidPhone: (phone: string) => /^\+[0-9]{1,3}9?[0-9]{9}$/.test(phone),
  isValidAddress: (add: string) => /^(.+, ?.+){3}$/.test(add),
  isValidString: (str: string) => str.trim().length > 0,
  isValidAlphanumString: (str: string, limit?: number) => /^[a-zA-ZÀ-ÖØ-öø-ÿ0-9 ]+$/.test(str.trim()) && ((!limit) || str.trim().length <= limit),
};

export function getUserLocation(): Coords {
  const userCoords = JSON.parse(sessionStorage.getItem('userLocation') || 'null') as UserCoords | null;
  if (userCoords) {
    const { lat, lon } = userCoords;
    return { lat, lon };
  }
  return defaultCoords;
}

/** Debounce hace que una función pueda ser llamada como máximo
 * una vez cada `milliseconds`.
 *
 * Esto sirve para los input con autocompletar: no queremos que cada tecla
 * haga una llamada a la API, sino que esperamos a que el usuario deje de tipear
 * durante `milliseconds` milisegundos y recién ahí hacemos la llamada a la API.
 */
export function debounce<T extends unknown[]>(
  func: (...args: T) => void,
  milliseconds: number,
): (...args: T) => void {
  let id: number | undefined;

  const callFunc = (...args: T) => {
    clearTimeout(id);
    id = undefined;
    func(...args);
  };

  return (...args: T) => {
    clearTimeout(id);
    id = setTimeout(callFunc, milliseconds, ...args) as unknown as number;
  };
}

// Usar esto cuando se quiera usar typescript + props en los componentes
// Ver algún caso de uso.
export function propOfType<T>(defaultValue: any = {}) {
  if (Array.isArray(defaultValue)) {
    return defaultValue;
  }
  return { default: defaultValue as T };
}

export function propOfTypeArrayOf<T>(defaultValue: T[] = []) {
  return { default: () => defaultValue.slice() };
}

/** Funciona igual al `mapNotNull` de Kotlin. También se le podría llamar `filterMap` porque
 * hace un `map` y un `filter` al mismo tiempo. */
export function arrayMapNotNull<A, B>(array: Array<A>, fn: (a: A) => B | null): Array<B> {
  return array.map((a) => fn(a))
    .filter((b) => b !== null) as Array<B>;
}

/** Esta función es útil para construir pares `[a, b]` y que el compilador lo entienda
 * como un par.
 *
 * Por ejemplo:
 *
 * ```typescript
 * [1, "hola"] // Array<number | string>
 * pair(1, "hola") // [number, string]
 * ```
 */
export function pair<A, B>(a: A, b: B): [A, B] {
  return [a, b];
}

/** Igualdad estructural.
 * Lo opuesto a la igualdad estructural es la "igualdad referencial", que
 * es lo que hacen el `==` y el `===`.
 *
 * La igualdad estructural se puede usar para ver si dos objetos son *estructuralmente*
 * iguales por más que sean diferentes referencias:
 *
 * ```typescript
 * const a = {}
 * a === a // true, son la misma referencia
 * a === {} // false, son distintas referencias
 * {} === {} // false, ídem
 * equals(a, {}) // true, tienen la misma estructura
 * equals({ id: 5 }, { id: 5 }) // true
 * equals({ id: 5 }, { id: 6 }) // false
 * ```
 */
export function equals<A>(a: A, b: A): boolean {
  if (a === b) {
    return true;
  }

  if (a instanceof Array && b instanceof Array) {
    return a.length === b.length && a.every((x, i) => equals(x, b[i]));
  }

  if (isObject(a) && isObject(b)) {
    for (const [key, value] of Object.entries(a)) {
      if (!(key in b) || !equals(value, b[key])) {
        return false;
      }
    }

    for (const key of Object.keys(b)) {
      if (!(key in a)) {
        return false;
      }
    }

    return true;
  }

  // Los valores NaN no son iguales entre si.
  // Si no agrego esto, `equals(NaN, NaN)` devolvería `false`.
  if (Number.isNaN(a) && Number.isNaN(b)) {
    return true;
  }

  return a === b;
}

/** No tengo idea como traducir el nombre de esta funcion.
 *
 * Agrupa los elementos del arreglo de a tres. Devuelve una tupla
 * con grupos de tres a la izquierda y con el "resto" a la derecha.
 *
 * El resto son los elementos que no se pudieron agrupar de a 3 porque no llegaban
 * a completar un grupo.
 *
 * Lo necesito en un layout donde quiero mostrar items en tres columnas.
*/
export function agruparDeATres<A>(array: Array<A>): [Array<[A, A, A]>, [A, A] | [A] | []] {
  if (array.length === 0) {
    return pair([], []);
  }
  if (array.length === 1) {
    return pair([], [array[0]]);
  }
  if (array.length === 2) {
    return pair([], [array[0], array[1]]);
  }

  const firstGroup: [A, A, A] = [array[0], array[1], array[2]];

  const [otherGroups, remainder] = agruparDeATres(array.slice(3));

  return pair([firstGroup, ...otherGroups], remainder);
}

export function isObject(a: unknown): a is { [key: string]: unknown } {
  return typeof a === 'object' && a !== null;
}

export function isFirefox(): boolean {
  return Boolean(navigator.userAgent.match(/firefox|fxios/i));
}

export function OSName(): 'Android' | 'iOS' | 'Desktop' {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return 'Android';
  }
  if (/iPad|iPhone|iPod/.test(ua)) {
    return 'iOS';
  }
  return 'Desktop';
}

/** Convierte un arreglo en un diccionario para poder buscar elementos en O(1) */
export function toKeyValuePair<A, B>(
  array: Array<A>,
  getKeyValue: (a: A) => [string, B],
): { [key: string]: B | undefined } {
  const keyValuePair: { [key: string]: B | undefined } = {};

  for (const elem of array) {
    const [key, value] = getKeyValue(elem);

    keyValuePair[key] = value;
  }

  return keyValuePair;
}

/** Toma un 'hola' y retorna un 'Hola' */
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/** Función usada por la función 'exportToCsv'. */
const processRowOfCsv = (row) => {
  let finalVal = '';
  for (let j = 0; j < row.length; j += 1) {
    let innerValue = (row[j] === null || row[j] === undefined) ? '' : row[j].toString();
    if (row[j] instanceof Date) {
      innerValue = row[j].toLocaleString();
    }

    let result = innerValue.replace(/"/g, '""');
    if (/("|;|\n|,)/g.test(result) && !/^(='.*')$/g.test(result)) {
      result = `"${result}"`;
    }
    // Cambia todos los ' en ='{text}' a "
    if (/^(='.*')$/g.test(result)) { result = result.replace(/'/g, '"'); }
    if (j > 0) { finalVal += ','; }
    finalVal += result;
  }
  return `${finalVal}\n`;
};

// Se agrega msSaveBlob a Navigator para que no de error abajo
declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, filename: string) => boolean
  }
}

/** Dada una lista de la forma [['a', 'b'], ['c', 'd']] genera un archivo csv y lo descarga.
 * Esta función fue extraida de:
 * https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 * No se usó la primer respuesta porque la primer respuesta falla cuando algún elemento
 * de 'rows' tiene una coma.
*/
export function exportToCsv(filename: string, rows: Array<unknown>) {
  let csvFile = '\uFEFF';
  rows.forEach((e) => {
    csvFile += processRowOfCsv(e);
  });

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  downloadFile(blob, filename);
}

export function downloadFile(file: Blob, filename: string, reportName?: string) {
  logEvent(getAnalytics(), 'descarga_reporte', {
    tipo_reporte: reportName ? msg.getReports(reportName) : filename,
  });

  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(file, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(file);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link); // For FireFox
      link.click();
      document.body.removeChild(link);
    }
  }
}

/** Devuelve lo mismo que recibe. */
export function identity<A>(value: A): A {
  return value;
}

/** Leer ticket TX-1881 */
export function fromPhoneToWhatsappValidPhone(phone: string) {
  return phone.slice(0, 1) === '+' ? phone : `+54${phone}`;
}

export function formatDate(date: Date): string {
  return `${formatDay(date)} - ${formatTime(date)}`;
}

export function formatDay(date: Date): string {
  const string = `${date.toLocaleDateString('es-AR', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })}`;
  // Capitalize first letter
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatTime(date: Date): string {
  const string = `${date.toLocaleTimeString('es-AR', {
    hour: '2-digit',
    minute: '2-digit',
  })}hs`;
  // Capitalize first letter
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDayAndMonth(date: Date): string {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  return `${day}/${month}`;
}

export function isToday(date: Date): boolean {
  const today = new Date();
  return date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear();
}

export function getCurrentTimestamp(): string {
  const date = new Date();

  const day = date.toLocaleDateString('es-AR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).replaceAll('/', '-');

  const time = date.toLocaleTimeString('es-AR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return `${day} ${time}`;
}

export function getFullName(pax: GenericPerson | undefined): string {
  if (pax) {
    if (pax.name) {
      return capitalizeText(pax.name);
    }
    if (pax.firstName && pax.lastName) {
      return capitalizeText(`${pax.lastName}, ${pax.firstName}`);
    }
    return capitalizeText(pax.firstName || pax.lastName);
  }
  return '';
}

export function copyStringToClipboard(str: string): Promise<void> {
  return navigator.clipboard.writeText(str);
}

export function formatAddress(address: Direccion): string {
  return capitalizeText(address.alias || address.direccion);
}

export function getNameOrUsername(person?: GenericPerson | Person | null): string {
  if (person) {
    return person?.username?.toLowerCase() || capitalizeText(`${person.firstName} ${person.lastName}`.trim());
  }
  return '-';
}

// recibe un numero en string y lo formatea
// puntos para separar miles y coma para decimales
export function formatStringNumber(number: string): string {
  const [parteEntera, parteDecimal] = number.split('.');
  const parteEnteraFormateada = parteEntera.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parteDecimal ? `${parteEnteraFormateada},${parteDecimal}` : parteEnteraFormateada;
}

export function getPassengersList(seguimientoData: SeguimientoData): PassengerModel[] {
  const passengers: PassengerModel[] = [];
  // Por cada pasaje, creamos un pasajero del componente Passenger.vue
  Object.values(seguimientoData.pasajes).forEach((e) => {
    const fromIndex = e.paradas[0];
    const toIndex = e.paradas[1];
    if (fromIndex !== undefined && toIndex !== undefined) {
      const from = formatAddress(seguimientoData.paradas[fromIndex]);
      const to = formatAddress(seguimientoData.paradas[toIndex]);
      passengers.push({
        person: e.pasajero || null,
        emisor: e.despachante || null,
        receptor: e.receptor || null,
        paradas: [from, to],
        from,
        to,
        ceco: null,
        obs: null,
      });
    }
  });
  return reducePaxs(passengers);
}

export function getDetailsPaxList(pasajes: SolicitudPasaje[], paradas: SolicitudParada[]):
PassengerModel[] {
  const passengers: PassengerModel[] = [];
  // Por cada pasaje, creamos un pasajero del componente Passenger.vue
  Object.values(pasajes).forEach((pasaje) => {
    const origenIndex = pasaje.paradas.length / 2 - 1;
    const destinoIndex = pasaje.paradas.length - 1;
    const origen = paradas.find((parada) => parada.idParada === pasaje.paradas[origenIndex]);
    const destino = paradas.find((parada) => parada.idParada === pasaje.paradas[destinoIndex]);
    if (origen !== undefined && destino !== undefined) {
      const from = formatAddress(origen.dir);
      const to = formatAddress(destino.dir);
      passengers.push({
        person: pasaje.pasajero as GenericPerson || null,
        emisor: pasaje.despachante as GenericPerson || null,
        receptor: pasaje.receptor as GenericPerson || null,
        paradas: [from, to],
        from,
        to,
        ceco: pasaje.ceco || null,
        obs: pasaje.obs || null,
      });
    }
  });
  return reducePaxs(passengers);
}

// esta funcion se encarga de reducir los pasajeros que se repiten en la lista,
// los une en un solo objeto y le agrega las paradas
function reducePaxs(passengers: PassengerModel[]): PassengerModel[] {
  return passengers.reduce((newPaxs, passenger) => {
    const paxFinded = newPaxs.find((p) => (
      getNameOrUsername(p.person) === getNameOrUsername(passenger.person)));
    const esPaquete = passenger.emisor !== null && passenger.receptor !== null;
    if (paxFinded && paxFinded.paradas && !esPaquete) {
      paxFinded.paradas.push(passenger.to);
    } else {
      newPaxs.push(passenger);
    }
    return newPaxs;
  }, [] as PassengerModel[]);
}
